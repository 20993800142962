.Header {
  position: relative;
  color: #fff;
  text-align: center;
  padding: 50px;
  width: 100%;
}

.Header__bulb {
  width: 100px;
  position: relative;
}

.Header__title {
  font-size: 60px;
  position: relative;
}

.Header__title-span {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 30px;
  display: block;
  position: relative;
}

.Header__slider {
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
}

.Header__slide-title {
  font-size: 40px;
}
