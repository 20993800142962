.Card {
  background: #f6f6f6;
  border: 1px solid #dadada;
  border-radius: 3px;
  padding: 10px;
  font-size: 14px;
  position: relative;
}

.Card:not(:last-of-type) {
  margin-bottom: 30px;
}

.Card--callback .Card__title {
  color: #f08d49;
}

.Card--callback .Card__title::after,
.Card--callback .Card__type {
  background-color: #f08d49;
  color: #fff;
}

.Card--responsiveObject .Card__title {
  color: #1db61a;
}

.Card--responsiveObject .Card__title::after,
.Card--responsiveObject .Card__type {
  background-color: #1db61a;
  color: #fff;
}

.Card--option .Card__title {
  color: #596cd3;
}

.Card--option .Card__title::after,
.Card--option .Card__type {
  background-color: #596cd3;
  color: #fff;
}

.Card--property .Card__title {
  color: #ce4edd;
}

.Card--property .Card__title::after,
.Card--property .Card__type {
  background-color: #ce4edd;
  color: #fff;
}

.Card__type {
  top: -15px;
  right: -1px;
  border: 1px solid #dadada;
  border-radius: 3px;
  padding: 2px 5px;
  text-decoration: none;
  color: #fff;
  transition: background-color 200ms ease-out;
  position: absolute;
}

.Card__title {
  margin-bottom: 20px !important;
  position: relative;
  font-size: 16px;
}

.Card__title::after {
  display: block;
  content: " ";
  height: 1px;
  width: 40px;
  bottom: -10px;
  position: absolute;
}

.Card__description {
  text-align: justify;
}
