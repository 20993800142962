@font-face {
  font-family: "Montserrat-Thin";
  src: local("Montserrat-Thin"),
    url("./fonts/Montserrat-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./fonts/Montserrat-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #454545;
  background: linear-gradient(to top left, #00b7ff, #151fae);
}

a {
  color: #3769ca;
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main {
  width: calc(100% - 40px);
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 20px;
}

.section {
  background-color: #fff;
  margin: 20px 0;
  padding: 20px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
}

.section-title {
  font-size: 40px;
  margin: 0 0 30px;
  position: relative;
  font-family: "Montserrat-Thin";
}

.section-title::after {
  content: " ";
  width: 100px;
  height: 1px;
  background-color: #454545;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.section-subtitle {
  margin-bottom: 10px;
}

.section p:not(:last-child) {
  margin-bottom: 10px;
}

#callback-log {
  box-sizing: border-box;
  width: 100%;
  height: 150px;
  resize: vertical;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #888;
}

.code {
  background-color: #1e1e1e;
  color: #fff;
  padding: 10px;
  border-radius: 3px;
}

.code:not(:last-child) {
  margin-bottom: 20px;
}

.keyword {
  color: #9cdcfe;
}

.value {
  color: #ce723b;
}

.tag {
  color: #2566ca;
}

.tag-bracket {
  color: #888;
}

.class {
  color: #4ec9b0;
}

.optionvalue {
  color: #b5ce89;
}
