.default-slider,
.responsive-slider,
.infinite-slider {
  margin-bottom: 30px;
}

.default-slider__slide,
.infinite-slider__slide,
.callback-slider__slide {
  text-align: center;
  padding: 2rem;
  border: 1px solid #888;
}

.thumbnail-slider-large {
  border: 1px solid #888;
  cursor: grab;
}

.thumbnail-slider-large__slide {
  text-align: center;
  overflow: hidden;
  line-height: 0;
}

.thumbnail-slider-large__slide img {
  max-width: 100%;
}

.thumbnail-slider-large__slide:active {
  cursor: grabbing;
}

.thumbnail-slider-small {
  margin-top: 20px;
  width: calc(100% + 20px);
  margin-left: -10px;
}

.thumbnail-slider-small__slide {
  opacity: 0.6;
  margin: 0 10px;
  overflow: hidden;
  height: 150px;
  position: relative;
  cursor: pointer;
}

@media (max-width: 660px) {
  .thumbnail-slider-small__slide {
    height: 100px;
  }
}

.thumbnail-slider-small__slide img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  max-width: 300px;
}

.thumbnail-slider-small__slide--active {
  opacity: 1;
}

.responsive-slider {
  margin-left: -10px;
  width: calc(100% + 20px);
}

.responsive-slider__slide {
  text-align: center;
  padding: 2rem;
  border: 1px solid #888;
  margin: 10px;
}
