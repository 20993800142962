.callback-checkbox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  border: 1px solid #dedede;
}

.callback-checkbox__container {
  flex-grow: 1;
  border: 1px solid #dedede;
  padding-right: 10px;
}

.callback-checkbox__label {
  padding: 10px;
  display: inline-block;
}
